export const API_URL =
  "https://hqauto.appgrowthcompany.com/api/v1/admin/"; //development


// export const API_URL =
  // "https://api.hqgroupofcompanies.com/api/v1/admin"; //live



export const GOOGLE_API_KEY = "AIzaSyDCPXe1uG5I33DUolLabetP37LtcOa3yqQ";

export const END_POINTS = {
  //auth
  signUp: "signup",
  login: "login",
  forgotPassword: "forgotPassword",
  getProfile: "profile",
  updateProfile: "updateProfile",
  mediaUpload: "upload",
  verifyOtp: "verifyOtp",
  setPassword: "setPassword",
  changePassword: "changePassword",
  logout: "logout",

  //manage users
  user: "user",
  userReports: "userReports",
  getProductsByUserId: "getProductsByUserId",
  userExports: "userExports",

  //cms
  get_cms: "cms?panel=",
  post_cms: "cms",
  put_cms: "cms/",
  cmsPrivacyPolicy:"cms/privacy-policy",
  // faq
  faq: "faq",

  // varients
  variant: "variant",

  //category
  category: "category",
  subCategory: "subCategory",
  subCategoryById: "subCategoryById",

  //manage Product
  product: "product",
  getProductsByCategoryId: "getProductsByCategoryId",
  getProductsBySubCategoryId: "getProductsBySubCategoryId",

  //manage Banners
  banners: "banner",

  //orders
  orders: "orders",
  updateOrderStatus: "updateOrderStatus",
  getOrdersByUser: "getOrdersByUser",

  //inventory management
  productVariant: "productVariant",

  //geofence
  geofence: "geofence",

  //sub-admin
  subAdmin: "subAdmin",
  subAdminRole: "subAdminRole",

  //coupon
  coupon: "coupon",

  //Dashboard
  adminDashboard: "adminDashboard",

  //getRatingByProductId
  getRatingByProductId: "getRatingByProductId",
  deleteRating: "deleteRating",

  //userExportsXL
  userExportsXL: "userExportsXL",

  //graphs
  userGraph: "userGraph",
  totalRevenuGraph: "totalRevenuGraph",
  totalProductsGraph: "totalProductsGraph",
  totalOrderGraph: "totalOrderGraph",

  //notification
  notification: "notification",
  adminNotification: "adminNotification",

  //payments
  payments: "payments",

  //bulkOrder
  bulkOrder: "bulkOrder",

  //csv's
  productsExports: "productsExports",
  ordersExports: "ordersExports",
  paymentsExports: "paymentsExports",

  //factory Tour
  factoryTour: "factoryTour",
};

import { Routes, Route } from "react-router-dom";
import Pages from "./pages";
import UserDetails from "./pages/users/details";
import UsersForm from "./pages/users/form/usersForm";
import ProtectedRoutes from "./protectedRoutes";

const Routing = () => {
  return (
    <Routes>

      {/* unprotected routes */}
      <Route path="/privacy-policy" element={<Pages.PrivacyPolicy />} />
      <Route path="/" element={<Pages.LoginPage />} />
      <Route path="forgotpassword" element={<Pages.ForgotPasswordPage />} />
      <Route path="verifyotp" element={<Pages.VerifyOtp />} />
      <Route path="resetpassword" element={<Pages.ResetPassword />} />


      {/* Protected routes */}
      <Route element={<ProtectedRoutes />}>
        <Route path="changepassword" element={<Pages.ChangePassword />} />
        <Route path="/dashboard" element={<Pages.DashBoardPage />} />
        <Route path="/profile" element={<Pages.Profile />} />
        <Route path="/manage-customers" element={<Pages.ManageUsers />} />
        <Route path="/manage-customers/userdetails/:id" element={<UserDetails />} />
        <Route path="/manage-customers/usersform/edit/:id" element={<UsersForm />} />
        <Route path="/manage-customers/usersform" element={<UsersForm />} />
        <Route path="/analytics" element={<Pages.Analytics />} />
        <Route path="/faq" element={<Pages.Faq />} />
        <Route path="/cms" element={<Pages.ManageCms />} />
        <Route path="/manage-bulk-orders" element={<Pages.ManageBulkOrders />} />
        <Route path="/manage-bulk-orders/details/:id" element={<Pages.BulkOrderDetails />} />
        <Route path="/product-order-management" element={<Pages.ManageServiceProvider />} />
        <Route path="/product-order-management/details/:id" element={<Pages.ProductOrderDetails />} />
        <Route path="/manage-notifications" element={<Pages.ManageNotifications />} />
        <Route path="/manage-notifications/add" element={<Pages.AddNotification />} />
        <Route path="/recieved-notifications" element={<Pages.RecievedNotifications />} />
        <Route path="/customer-support" element={<Pages.CustomerSupport />} />
        <Route path="/manage-subAdmin" element={<Pages.ManageSubAdmin />} />
        <Route path="/manage-subAdmin/add" element={<Pages.AddSubAdmin />} />
        <Route path="/manage-subAdmin/edit/:id" element={<Pages.AddSubAdmin />} />
        <Route path="/manage-categories" element={<Pages.ManageCategories />} />
        <Route path="/manage-categories/add" element={<Pages.AddCategories />} />
        <Route path="/manage-categories/details/:id" element={<Pages.SubCategories />} />
        <Route path="/manage-categories/edit/:id" element={<Pages.AddCategories />} />
        <Route path="/manage-amenities" element={<Pages.ManageAmenities />} />
        <Route path="/manage-amenities/add" element={<Pages.AddAmenities />} />
        <Route path="/manage-reviews" element={<Pages.ManageReviews />} />
        <Route path="/manage-reviews/details/:id" element={<Pages.ReviewDetails />} />
        <Route path="/manage-revenue" element={<Pages.ManageRevenue />} />
        <Route path="/manage-revenue/payment-details/:id" element={<Pages.PaymentDetails />} />
        <Route path="/ad-banner-management" element={<Pages.AdBanner />} />
        <Route path="/ad-banner-management/add" element={<Pages.AddBanner />} />
        <Route path="/ad-banner-management/edit/:id" element={<Pages.AddBanner />} />
        <Route path="/inventory-management" element={<Pages.ManageInventory />} />
        <Route path="/inventory-management/details/:id" element={<Pages.ContentDetails />} />
        <Route path="/inventory-management/details/variants/:id" element={<Pages.ManageVariants />} />
        <Route path="/manage-products" element={<Pages.ManageProducts />} />
        <Route path="/manage-products/details/:id" element={<Pages.ContentDetails />} />
        <Route path="/manage-products/add" element={<Pages.AddProductForm />} />
        <Route path="/manage-products/edit/:id" element={<Pages.AddProductForm />} />
        <Route path="/discount-and-coupon" element={<Pages.ManageDiscount />} />
        <Route path="/discount-and-coupon/add" element={<Pages.AddDiscount />} />
        <Route path="/discount-and-coupon/edit/:id" element={<Pages.AddDiscount />} />
        <Route path="/manage-sizes" element={<Pages.ManageSizes />} />
        <Route path="/manage-sizes/add-size" element={<Pages.AddSize />} />
        <Route path="//manage-sizes/edit-size/:id" element={<Pages.AddSize />} />
        <Route path="/manage-geofence" element={<Pages.ManageGeofence />} />
        <Route path="/manage-geofence/add" element={<Pages.AddGeofence />} />
        <Route path="/manage-geofence/edit/:id" element={<Pages.AddGeofence />} />
        <Route path="/manage-factory-tour" element={<Pages.FactoryTourIndex />} />
        <Route path="/manage-factory-tour/add" element={<Pages.AddFactoryTour />} />
        <Route path="/manage-factory-tour/edit/:id" element={<Pages.AddFactoryTour />} />
      </Route>
    </Routes>
  );
};

export default Routing;

import React, { useEffect, useState } from 'react'
import { useLazyGetPrivacyPolicyQuery } from '../../services/cms'
import { showError } from '../../constants/toast';
import Loader from '../../constants/Loader';
import { Box } from '@mui/material';

export default function PrivacyPolicy() {


    const [privacyPolicy, setPrivacyPolicy] = useState<any>()
    const [fetchData] = useLazyGetPrivacyPolicyQuery();
    const [isLoading, setIsLoading] = useState<boolean>(false)


    const fetchPrivacyPolicy = async () => {
        setIsLoading(true)
        try {
            const res = await fetchData({}).unwrap();
            setIsLoading(false)
            if (res?.statusCode === 200) {
                setPrivacyPolicy(res?.data?.privacyPolicy)
            }
        } catch (error: any) {
            setIsLoading(false)
            showError(error?.data?.message)
        }
    }


    useEffect(() => {
        fetchPrivacyPolicy()
    }, [])

    return (
        <div className='privacy_policy'>
            <Loader isLoad={isLoading} />
            <Box
                m={0}
                className="auth_logo"
                sx={{ display: "flex", justifyContent: "center" }}
            >
                <figure className="logo_cntnr" >
                    <img style={{
                    width: "100px",
                    marginTop: "15%"
                }} src="/static/images/logo.png" alt="" />
                </figure>
            </Box>
            <h1 style={{
                textAlign: "center",
                padding: "20px"
            }}>Privacy Policy</h1>
            <div style={{
                textAlign: "center",
                padding: "3px"
            }} dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
        </div >
    )
}

import React, { useState, useEffect } from "react";
import MainContainer from "../../layout/MainContainer";
import { Button, Card, Grid, TextField, Typography } from "@mui/material";
import EditText from "../../components/EditText";
import PhoneInput from "react-phone-input-2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "react-phone-input-2/lib/bootstrap.css";
import Faq from "../faq";
import { useFormik } from "formik";
import * as Yup from "yup";
import { showError, showToast } from "../../constants/toast";
import { usePutCmsMutation } from "../../services/cms";
import { useLazyGetCmsQuery } from "../../services/cms";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { WhatsApp } from "@mui/icons-material";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";
import { useNavigate } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageCms = () => {
  const navigate = useNavigate();
  const [privacyPolicy, setPrivacyPolicy] = useState<string>("");
  const [privacyPolicy_ar, setPrivacyPolicy_ar] = useState<string>("");
  const [termsAndCondition, setTermsAndConditions] = useState<string>("");
  const [termsAndCondition_ar, setTermsAndConditions_ar] = useState<string>("");
  const [contract, setContract] = useState<string>("");
  const [contract_ar, setContract_ar] = useState<string>("");
  const [value, setValue] = React.useState(0);
  const [phoneCode, setPhoneCode] = useState("+91");
  const [whatsappPhoneCode, setWhatsappPhoneCode] = useState("+91");
  const [putCms] = usePutCmsMutation();

  const [getCms] = useLazyGetCmsQuery();

  const UserData = useAuth();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);


  const userPermissions = UserData?.permissions?.length
    ? (UserData?.permissions)
    : [];

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage CMS"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      phone: "",
      whatsappNumber: "",
      countryCode: "",
      whatsappCountryCode: "",
      privacyPolicy: "",
      privacyPolicy_ar: "",
      termsAndCondition: "",
      termsAndCondition_ar: "",
      contract: "",
      contract_ar: "",
      mainLandChargePercent: "",
      advancePaymentChargePercent: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        // .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),

      phone: Yup.string()
        // .required("Phone number is required")
        .min(6, "Phone number must be at least 6 characters")
        .max(16, "Phone number must be at least 16 characters"),

      whatsappNumber: Yup.string()
        // .required("WhatsApp number is required")
        .min(6, "WhatsApp number must be at least 6 characters")
        .max(16, "WhatsApp number must be at least 16 characters"),


      advancePaymentChargePercent: Yup.number()
        // .required("Advance payment percentage is required")
        .max(100, "Advance payment percentage cannot be more than 100")
        .min(0, "Advance payment percentage cannot be negative"),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let body = {
        email: formik.values.email,
        phone: formik.values.phone,
        whatsappNumber: formik.values.whatsappNumber,
        countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
        whatsappCountryCode: (whatsappPhoneCode.includes("+") ? "" : "+") + whatsappPhoneCode,
        // countryCode:"+91",
        // whatsappCountryCode:"+91",
        contract: contract,
        contract_ar: contract_ar,
        privacyPolicy: privacyPolicy,
        privacyPolicy_ar: privacyPolicy_ar,
        termsAndCondition: termsAndCondition,
        termsAndCondition_ar: termsAndCondition_ar,
        advancePaymentChargePercent: formik.values.advancePaymentChargePercent,
        mainLandChargePercent: formik.values.mainLandChargePercent,

      };
      console.log(body, "body..............");

      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await putCms(encryptedBody).unwrap();
        if (response.statusCode === 200) {
          showToast("Cms updated succesfully")
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
    }
  })

  const getAllCms = async () => {
    try {
      const response = await getCms({}).unwrap();
      if (response.statusCode === 200) {
        // const cmsData = response?.data;
        formik.setValues({
          email: response?.data?.email || "",
          phone: response?.data?.phone || "",
          whatsappNumber: response?.data?.whatsappNumber || "",
          // countryCode: response?.data?.countryCode || "+91",
          countryCode: "+91",
          // whatsappCountryCode: response?.data?.whatsappCountryCode || "+91",
          whatsappCountryCode: "+91",
          contract: response?.data?.contract || "",
          contract_ar: response?.data?.contract_ar || "",
          privacyPolicy: response?.data?.privacyPolicy || "",
          privacyPolicy_ar: response?.data?.privacyPolicy_ar || "",
          termsAndCondition: response?.data?.termsAndCondition || "",
          termsAndCondition_ar: response?.data?.termsAndCondition_ar || "",
          mainLandChargePercent: response?.data?.mainLandChargePercent?.toString() || "",
          advancePaymentChargePercent: response?.data?.advancePaymentChargePercent?.toString() || "",

        });
        setTermsAndConditions(response?.data?.termsAndCondition || "");
        setTermsAndConditions_ar(response?.data?.termsAndCondition_ar || "");
        setPrivacyPolicy(response?.data?.privacyPolicy || "");
        setPrivacyPolicy_ar(response?.data?.privacyPolicy_ar || "");
        const phone = response?.data?.countryCode
        setPhoneCode(phone || "");
        const phone1 = response?.data?.whatsappCountryCode
        setWhatsappPhoneCode(phone1 || "");
        setContract(response?.data?.contract || "")
        setContract_ar(response?.data?.contract_ar || "")
      }

    } catch (error: any) {
      showError(error?.data?.message || "");
      
    }
  }

  useEffect(() => {
    checkPermission()
  }, [UserData])

  useEffect(() => {
    getAllCms();
  }, []);

  const handleChangePhone = (phone: any, country: any) => {
    console.log(country, "country");
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
  };

  const handleChangeWhatsapp = (phone: any, country: any) => {
    formik.setFieldValue("whatsappNumber", phone?.replace(country.dialCode, ""));
    setWhatsappPhoneCode(country?.dialCode);
  };


  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage CMS</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Contact Support" {...a11yProps(0)} />
              <Tab label="Privacy Policy" {...a11yProps(1)} />
              <Tab label="Terms and Conditions" {...a11yProps(2)} />
              <Tab label="Faq's" {...a11yProps(3)} />
              <Tab label="Payment settings" {...a11yProps(4)} />
              <Tab label="Contract Policy" {...a11yProps(5)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Email</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"email"}
                      name="email"
                      variant="outlined"
                      fullWidth
                      placeholder="Email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={
                        (formik.touched.email && formik.errors.email) || ""
                      }
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Phone Number
                    </Typography>
                    <PhoneInput
                      enableSearch={true}
                      country={"in"}
                      placeholder="Phone Number"
                      inputClass="phoneInput"
                      value={phoneCode + formik.values.phone}
                      inputStyle={{ width: "100%" }}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <h6 className="err_msg">
                        {formik.touched.phone && formik.errors.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      WhatsApp Number
                    </Typography>
                    <PhoneInput
                      enableSearch={true}
                      country={"in"}
                      placeholder="WhatsApp Number"
                      inputClass="phoneInput"
                      value={whatsappPhoneCode + formik.values.whatsappNumber}
                      inputStyle={{ width: "100%" }}
                      onChange={(phone, country) =>
                        handleChangeWhatsapp(phone, country)
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.whatsappNumber && formik.errors.whatsappNumber && (
                      <Typography variant="body2" className="err_msg">
                        {formik.errors.whatsappNumber}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <div className="form_btn">
                  {hidePermission?.isEdit || UserData?.role === 1 ? (
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      Save
                    </Button>
                  ) : null}
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <form onSubmit={formik.handleSubmit}>
                <h3>Privacy Policy (English)</h3>
                <EditText
                  content={privacyPolicy}
                  setContent={setPrivacyPolicy}
                />
                <h3>Privacy Policy (Arabic)</h3>
                <EditText
                  content={privacyPolicy_ar}
                  setContent={setPrivacyPolicy_ar}
                />
                <div className="form_btn">
                  {hidePermission?.isEdit || UserData?.role === 1 ? (
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      Save
                    </Button>
                  ) : null}
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <form onSubmit={formik.handleSubmit}>
                <h3>Terms and Conditions (English)</h3>
                <EditText
                  content={termsAndCondition}
                  setContent={setTermsAndConditions}
                />
                <h3>Terms and Conditions (Arabic)</h3>
                <EditText
                  content={termsAndCondition_ar}
                  setContent={setTermsAndConditions_ar}
                />
                <div className="form_btn">
                  {hidePermission?.isEdit || UserData?.role === 1 ? (
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      Save
                    </Button>
                  ) : null}
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <Faq />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Main Land Charge (Percentage)</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="mainLandChargePercent"
                      variant="outlined"
                      fullWidth
                      placeholder="Main land charge"
                      value={formik.values.mainLandChargePercent}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={
                        (formik.touched.mainLandChargePercent && formik.errors.mainLandChargePercent) || ""
                      }
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Advance Payment (Percentage)
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="advancePaymentChargePercent"
                      variant="outlined"
                      fullWidth
                      placeholder="Advance Payment"
                      value={formik.values.advancePaymentChargePercent}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      helperText={
                        (formik.touched.advancePaymentChargePercent && formik.errors.advancePaymentChargePercent) || ""
                      }
                    />
                  </Grid>
                </Grid>
                <div className="form_btn">
                  {hidePermission?.isEdit || UserData?.role === 1 ? (
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      Save
                    </Button>
                  ) : null}
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={5}>
              <form onSubmit={formik.handleSubmit}>
                <h3>Contract Policy (English)</h3>
                <EditText
                  content={contract}
                  setContent={setContract}
                />
                <h3>Contract Policy (Arabic)</h3>
                <EditText
                  content={contract_ar}
                  setContent={setContract_ar}
                />
                <div className="form_btn">
                  {hidePermission?.isEdit || UserData?.role === 1 ? (
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      Save
                    </Button>
                  ) : null}
                </div>
              </form>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageCms;

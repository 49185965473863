import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  FormControl,
  Grid,
  Input,
  MenuItem,
  NativeSelect,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useLazyGetAllUsersQuery, useLazyGetAllUserswithoutPageQuery } from "../../services/users";
import { CommonBody, UserData } from "../../types/General";
import { showError, showToast } from "../../constants/toast";
import { useDebouncedCallback } from "use-debounce";
import { useAddNotificationMutation } from "../../services/notification";
import * as Yup from "yup";
import { useFormik } from "formik";
import { UploadMedia } from "../../utils/mediaUpload";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import Multiselect from "multiselect-react-dropdown";
const AddNotification = () => {
  const navigate = useNavigate();
  const [receiver, setReceiver] = useState<string>("All");
  const [getAllUsers] = useLazyGetAllUsersQuery();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [searchInProgress, setSearchInProgress] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const selectRef = useRef<any>(null);
  const [image, setImage] = useState<string>();
  const [addNotification] = useAddNotificationMutation();
  const [users, setUsers] = useState<UserData[]>([])
  const [all, setAll] = useState<UserData[]>([])
  const [loading, setLoading] = useState<boolean>(false);
  const [typeU, setTypeU] = useState<number>();
  const [getAllWithoutPage] = useLazyGetAllUserswithoutPageQuery();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [page, setPage] = useState<number>(1);
  const [userDataForNotification, setUserDataForNotification] = useState<UserData[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const handleReceiverChange = (event: SelectChangeEvent) => {
    setReceiver(event.target.value as string);
  };

  console.log(selectedUsers, "selected");

  //get all user api
  const getAllUsersFun = async () => {
    try {
      const response = await getAllWithoutPage({
      },).unwrap();
      if (response?.statusCode === 200) {
        setUserDataForNotification(response?.data?.user);
      } else {
        setUserDataForNotification([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };



  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
  };

  const formattedAmenities = [{ name: "All", value: "all" }, ...userDataForNotification?.map(user => ({
    value: user._id,
    name: user?.fullName ? (user?.fullName) : (user?.firstName + " " + user?.lastName),
  }))];
const[selectedList,SetSelectedList]=useState<any>([]);
console.log('selectedList2: ', selectedList);
  // Function to handle onSelect event
  const handleSelect = (selectedList: any, selectedItem: any) => {
    console.log('selectedList1: ', selectedList);
    console.log(selectedItem.value,"selectedItem.value");
    SetSelectedList(selectedList)
    if (selectedItem.value === "all") {
      setSelectedUsers([]);
      SetSelectedList([ 
        {name: 'All', value: 'all'}]);
      setTypeU(1);
    } else {
      SetSelectedList([...selectedList?.filter((data:any)=>data.value!="all")])
      setSelectedUsers(selectedList?.filter((data:any)=>data.value!=="all")?.map((option: { value: any; }) => option?.value))
      setTypeU(2);
    }
  };

  // Function to handle onRemove event
  const handleRemove = (selectedList: any, removedItem: any) => {
    if (removedItem.value === "all") {
      setTypeU(2);
      setSelectedUsers([]);
    } else {
      setSelectedUsers(selectedList?.map((option: { value: any; }) => option?.value))
      const isAllSelected = selectedList.some((item: { value: string; }) => item.value === "all");
      if (isAllSelected) {
        setTypeU(1);
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      image: " ",
      title: "",
      title_ar: "",
      message: "",
      message_ar: "",
      userArray: "",
      type: "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("Title is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 characters are allowed"),

      title_ar: Yup.string()
        .required("Title is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 characters are allowed"),

      message: Yup.string()
        .required("Meassage is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 characters are allowed"),

      message_ar: Yup.string()
        .required("Meassage is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 characters are allowed"),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let data = {
        title: values.title,
        title_ar: values.title_ar,
        message: values.message,
        message_ar: values.message_ar,
        // image: image,
        userArray: selectedUsers,
        type: typeU,
      };
      console.log(data, "data......................")
      // if (!image) {
      //   showError("Image is required")
      //   return;
      // }

      if (typeU === 2 && selectedUsers.length === 0) {
        showError('Please select atleast one user')
        return;
      }



      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await addNotification(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          showToast("Notification added successfully!");
          navigate("/manage-notifications", { replace: true });
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }

    },
  });

  console.log(selectedUsers, "setSelectedUsers......");
  console.log(typeU, "typeU.............")

  useEffect(() => {
    getAllUsersFun();
  }, [page])

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">Add Notification</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-notifications");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}
              onKeyPress={(event: any) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                }
              }}
            >
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  {/* <Grid item xs={12}>
                    <Typography className="custom_label">Image</Typography>
                    {image ? (
                      <div className="upload_image_preview">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CancelIcon
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleImageUpload(e)}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src="/static/images/user_placeholder.png"
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid> */}
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Notification Title (English)
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      fullWidth
                      placeholder="Title"
                      className="text_field"
                      inputProps={{ maxLength: 30 }}
                      onBlur={formik.handleBlur}
                      value={formik.values.title}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      helperText={formik.touched.title && formik.errors.title}
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Notification Title (Arabic)
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title_ar"
                      variant="outlined"
                      fullWidth
                      placeholder="Title"
                      className="text_field"
                      inputProps={{ maxLength: 30 }}
                      onBlur={formik.handleBlur}
                      value={formik.values.title_ar}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      helperText={formik.touched.title_ar && formik.errors.title_ar}
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Notification Message (English)
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="message"
                      variant="outlined"
                      fullWidth
                      placeholder="Message"
                      className="text_field"
                      inputProps={{ maxLength: 30 }}
                      onBlur={formik.handleBlur}
                      value={formik.values.message}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      helperText={formik.touched.message && formik.errors.message}
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Notification Message (Arabic)
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="message_ar"
                      variant="outlined"
                      fullWidth
                      placeholder="Message"
                      className="text_field"
                      inputProps={{ maxLength: 30 }}
                      onBlur={formik.handleBlur}
                      value={formik.values.message_ar}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      helperText={formik.touched.message_ar && formik.errors.message_ar}
                    ></TextField>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Select Receiver</Typography>
                    <FormControl sx={{ width: "100%" }}>

                      <Multiselect
                        className='users_autocomplete'
                        options={formattedAmenities}
                        displayValue="name"
                        selectedValues={selectedList}
                        onSelect={handleSelect}
                        onRemove={handleRemove}
                      />


                    </FormControl>
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddNotification;

import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { CmsData, CommonBody } from "../types/General";

type CommonResponseType = {
    statusCode: number;
    message: string;
};

export const authApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        putCms: builder.mutation<
            CommonResponseType & { data: CmsData },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.put_cms,
                method: "PUT",
                body,
            }),
        }),
        getCms: builder.query<CommonResponseType & { data: CmsData }, {}>({
            query: () => ({
                url: END_POINTS.get_cms,
                method: "GET",
            }),
        }),
        getPrivacyPolicy: builder.query<CommonResponseType & { data: any }, {}>({
            query: () => ({
                url: END_POINTS.cmsPrivacyPolicy,
                method: "GET",
            }),
        }),

    })
})

export const {
    usePutCmsMutation,
    useLazyGetCmsQuery,
    useLazyGetPrivacyPolicyQuery
} = authApi;

import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, FormControl, Grid, MenuItem, NativeSelect, Paper, Select, Typography } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import InventoryIcon from "@mui/icons-material/Inventory";
import LineChart from "../../components/LineChart";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import PanoramaIcon from "@mui/icons-material/Panorama";
import { useNavigate } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useLazyGetDashboardQuery, useLazyGetOrderGraphQuery, useLazyGetProductGraphQuery, useLazyGetRevenueGraphQuery, useLazyGetUserGraphQuery } from "../../services/dashboard";
import { useLazyGetAllGeofenceQuery } from "../../services/geofence";
import { Dashboard, GeofenceData, TruckCategory } from "../../types/General";
import { useLazyGetCategoriesQuery } from "../../services/categories";
import { showError } from "../../constants/toast";
import { ExportModal1, ExportModal2, ExportModal3, ExportModal4 } from "../../components";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#204e33" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const DashBoard = () => {
  const [getAllGeofence] = useLazyGetAllGeofenceQuery();
  const [geoData, setGeoData] = useState<GeofenceData[]>([]);
  const [categoryData, setCategoryData] = useState<TruckCategory[]>([]);
  const [getAllCategories] = useLazyGetCategoriesQuery();
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedGeofence, setSelectedGeofence] = useState<string>("");
  const [selectedTime, setSelectedTime] = useState<number>(7);
  const [getDashboard] = useLazyGetDashboardQuery();
  const [dashData, setDashData] = useState<Dashboard>();
  const [userGraph] = useLazyGetUserGraphQuery();
  const [earningGraph] = useLazyGetRevenueGraphQuery();
  const [orderGraph] = useLazyGetOrderGraphQuery();
  const [productGraph] = useLazyGetProductGraphQuery();
  const [userGraphData, setUserGraphData] = useState<number[]>([]);
  const [userGraphName, setUserGraphName] = useState<string[]>([]);
  const [orderGraphData, setOrderGraphData] = useState<number[]>([]);
  const [orderGraphName, setOrderGraphName] = useState<string[]>([]);
  const [productGraphData, setgraphProductData] = useState<number[]>([]);
  const [productGraphName, setgraphProductName] = useState<string[]>([]);
  const [earningGraphData, setgraphEarningData] = useState<number[]>([]);
  const [earningGraphName, setgraphEarningName] = useState<string[]>([]);
  const [open1, setOpen1] = useState<boolean>(false);
  const [open2, setOpen2] = useState<boolean>(false);
  const [open3, setOpen3] = useState<boolean>(false);
  const [open4, setOpen4] = useState<boolean>(false);



  const graphRevenueData = {
    labels: earningGraphName,
    datasets: [
      {
        label: "Earnings",
        data: earningGraphData,
        borderColor: "#029be0",
        backgroundColor: "#029be0",
      },
    ],
  };


  const getEarningGraph = async () => {
    try {
      const response = await earningGraph({ type: selectedTime, categoryId: selectedCategory, geofenceId: selectedGeofence }).unwrap();
      if (response?.statusCode === 200) {
        const userKeys = Object?.keys(response?.data?.revenuGenerated) || [];
        const value = Object?.values(response?.data?.revenuGenerated) || []
        const values = value.map((val: unknown) => typeof val === 'number' ? val : 0);
        setgraphEarningName(userKeys)
        setgraphEarningData(values)
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getCategory = async () => {
    try {
      const response = await getAllCategories({
      }).unwrap();
      if (response?.statusCode === 200) {
        setCategoryData(response?.data?.category || []);
      } else {
        setCategoryData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const fetchGeofence = async () => {
    try {
      const res = await getAllGeofence({}).unwrap();
      if (res?.statusCode === 200) {
        setGeoData(res?.data?.data)
      }
    } catch (error: any) {
      showError(error?.data?.message)
    }
  }

  const fetchDashboard = async () => {
    try {
      const res = await getDashboard({
      }).unwrap();
      if (res?.statusCode === 200) {
        setDashData(res?.data);
        console.log(res, "res...........");
      }
    } catch (error) {
      console.log(error);

    }
  }


  const getUserGraph = async () => {
    try {
      const response = await userGraph({ type: selectedTime, categoryId: selectedCategory, geofenceId: selectedGeofence }).unwrap();
      if (response?.statusCode === 200) {
        const userKeys = Object?.keys(response?.data?.totalUsers) || [];
        const value = Object?.values(response?.data?.totalUsers) || []
        const values = value.map((val: unknown) => typeof val === 'number' ? val : 0);
        setUserGraphName(userKeys)
        setUserGraphData(values)
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getOrderGraph = async () => {
    try {
      const response = await orderGraph({ type: selectedTime, categoryId: selectedCategory, geofenceId: selectedGeofence }).unwrap();
      if (response?.statusCode === 200) {
        const userKeys = Object?.keys(response?.data?.totalOrder) || [];
        const value = Object?.values(response?.data?.totalOrder) || []
        const values = value.map((val: unknown) => typeof val === 'number' ? val : 0);
        setOrderGraphName(userKeys)
        setOrderGraphData(values)
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getProductGraph = async () => {
    try {
      const response = await productGraph({ type: selectedTime, categoryId: selectedCategory, geofenceId: selectedGeofence }).unwrap();
      if (response?.statusCode === 200) {
        const userKeys = Object?.keys(response?.data?.totalProduct) || [];
        const value = Object?.values(response?.data?.totalProduct) || []
        const values = value.map((val: unknown) => typeof val === 'number' ? val : 0);
        setgraphProductName(userKeys)
        setgraphProductData(values)
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  function convertToInternationalCurrencySystem(labelValue: number) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + " B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
        ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + " M"
        : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
          ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + " K"
          : Math.abs(Number(labelValue));
  }

  const handleChangeGeofence = (event: any) => {
    const value = event.target.value;
    setSelectedGeofence(value);
  };

  const handleChangeCategory = (event: any) => {
    const value = event.target.value;
    setSelectedCategory(value);
  };

  const isFilterApplied = selectedCategory !== '' || selectedGeofence !== '';


  const handleClearFilters = () => {
    setSelectedCategory('');
    setSelectedGeofence('');
  };


  useEffect(() => {
    fetchGeofence();
    fetchDashboard();
    getCategory();
  }, [])

  useEffect(() => {
    getUserGraph();
    getOrderGraph();
    getProductGraph();
    getEarningGraph();
  }, [selectedTime, selectedCategory, selectedGeofence]);


  const graphOrderData = {
    labels: orderGraphName,
    datasets: [
      {
        label: "Users",
        data: orderGraphData,
        borderColor: "#029be0",
        backgroundColor: "#029be0",
      },
    ],
  };
  const graphUsersData = {
    labels: userGraphName,
    datasets: [
      {
        label: "Orders",
        data: userGraphData,
        borderColor: "#029be0",
        backgroundColor: "#029be0",
      },
    ],
  };
  const graphProductData = {
    labels: productGraphName,
    datasets: [
      {
        label: "Products",
        data: productGraphData,
        borderColor: "#029be0",
        backgroundColor: "#029be0",
      },
    ],
  };

  const navigate = useNavigate();



  return (
    <div className="main_loyout">
      <div className="dashboard">
        <h1 className="mn_hdng">Dashboard</h1>
      </div>

      <Grid container spacing={2} className="dashGrid">
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-customers")}
          >
            <PeopleIcon className="svg_icn" />
            <div>
              <h3>Total Customer</h3>
              <h4 className="mn_hdng">{dashData?.userCount}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-products")}
          >
            <PanoramaIcon className="svg_icn" />
            <div>
              <h3>Total Products</h3>
              <h4 className="mn_hdng">{dashData?.productCount}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/product-order-management")}
          >
            <PanoramaIcon className="svg_icn" />
            <div>
              <h3>Total Orders</h3>
              <h4 className="mn_hdng">{dashData?.orderCount || "0"}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-customers", { state: { value: "New" } })}
          >
            <ThumbUpAltIcon className="svg_icn" />
            <div>
              <h3>New Customers</h3>
              <h4 className="mn_hdng">{dashData?.newUsers}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-revenue")}
          >
            <InventoryIcon className="svg_icn" />
            <div>
              <h3>Total Earnings</h3>
              <h4 className="mn_hdng">AED {convertToInternationalCurrencySystem(dashData?.totalEarning || 0)}</h4>
            </div>
          </Item>
        </Grid>{" "}
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-customers", { state: { value: "Live" } })}
          >
            <InventoryIcon className="svg_icn" />
            <div>
              <h3>Live users</h3>
              <h4 className="mn_hdng">{dashData?.liveUsers || "0"}</h4>
            </div>
          </Item>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="dashGrid" mt={4}>

        <Grid item lg={3} md={3} sm={3} xs={3}>
          <FormControl sx={{ width: "100%" }}>
            <Select
              className="select_div "
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              inputProps={{
                "aria-label": "Without label",
              }}
              displayEmpty
              value={selectedCategory}
              onChange={handleChangeCategory}
            >
              <MenuItem value="" disabled>
                Select Category
              </MenuItem>
              {categoryData?.map((item, index) => (
                <MenuItem value={item?._id}>{item?.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <FormControl sx={{ width: "100%" }}>
            <Select
              className="select_div "
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              displayEmpty
              inputProps={{
                "aria-label": "Without label",
              }}
              value={selectedGeofence}
              onChange={handleChangeGeofence}
            >
              <MenuItem value="" disabled>
                Select Location
              </MenuItem>
              {geoData?.map((item, index) => (
                <MenuItem value={item?._id}>{item?.geofenceName}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <FormControl sx={{ width: "250px" }}>
            <NativeSelect
              defaultValue={7}
              inputProps={{
                name: "report",
                id: "uncontrolled-native",
              }}
              onChange={(event) => setSelectedTime(Number(event.target.value))}
            >
              <option value={1}>All Time</option>
              <option value={2}>Last 1 year</option>
              {/* <option value={3}>Last 6 months</option> */}
              {/* <option value={4}>Last 3 months</option> */}
              <option value={5}>Last month</option>
              <option value={6}>Last week</option>
              <option value={7}>Today</option>
            </NativeSelect>
          </FormControl>

        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          {isFilterApplied && (
            <Button
              variant="outlined"
              color="primary"
              sx={{ marginLeft: "3px", marginTop: "5px", backgroundColor: "#029be0", color: "white", textTransform: "capitalize" }}
              onClick={handleClearFilters}
            >
              Clear filters
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} className="dashGraph" sx={{ pt: 4 }}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">Total Registered Users ({dashData?.productCount})
              <Button
                className="btn btn_primary"
                onClick={() => setOpen1(true)}
              >
                <FileDownloadIcon /> Export
              </Button></h2>
            <LineChart data={graphUsersData} />
          </Item>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">Total Orders ({dashData?.orderCount})
              <Button
                className="btn btn_primary"
                onClick={() => setOpen4(true)}
              >
                <FileDownloadIcon /> Export
              </Button></h2>
            <LineChart data={graphOrderData} />
          </Item>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">Total Products ({dashData?.productCount})
              <Button
                className="btn btn_primary"
                onClick={() => setOpen2(true)}
              >
                <FileDownloadIcon /> Export
              </Button></h2>
            <LineChart data={graphProductData} />
          </Item>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">
              Earnings (AED {convertToInternationalCurrencySystem(dashData?.totalEarning || 0)})
              <Button
                className="btn btn_primary"
                onClick={() => setOpen3(true)}
              >
                <FileDownloadIcon /> Export
              </Button>
            </h2>
            <LineChart data={graphRevenueData} />
          </Item>
        </Grid>
      </Grid>
      <ExportModal1
        open={open1}
        setOpen={setOpen1} />
      <ExportModal2
        open={open2}
        setOpen={setOpen2}
      />
      <ExportModal3
        open={open3}
        setOpen={setOpen3} />
      <ExportModal4
        open={open4}
        setOpen={setOpen4}
      />
    </div>
  );
};

export default DashBoard;
